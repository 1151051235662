import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import api from "../services/api";
import { CandidataType } from "./useCandidatas";



export function useCandidata() {
    const history = useNavigate()
    const params = useParams()
    const [candidata, setCandidata] = useState<CandidataType>()

    useEffect(() => {
        
        const apiCandidatas = api.get('getCandidata/id/'+params.id)
        .then((response) => {
            if(response.data.status==="OK"){
                setCandidata(response.data.dados)
            }else{
                localStorage.clear()
                alert(response.data.msg)
                history('/login')
            }
        })
    }, [])
    return {candidata}

}