import { useNavigate } from "react-router-dom";
import { CandidataType } from "../hooks/useCandidatas";
import { Button } from "./Button";

type CandidataTypeProps = {
    candidata: CandidataType
}

export function FooterCandidataHome({candidata}:CandidataTypeProps){
    const history = useNavigate()
    function vote(id:string){
        history('/vote/'+id)
    }
    return(
        <footer>
                <div className="user-info">
                    <span><b>{candidata.nome}</b></span>
                    <span>{candidata.cidade}</span>
                </div>
                <div>
                    <Button onClick={() => vote(candidata.id)}>Votar</Button>
                </div>
            </footer>
    )
}