import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import api from "../services/api"

export type CandidataType = {
    id: string
    nome: string
    cidade: string
    foto: string
}

export function useCandidatas() {
    const history = useNavigate()
    const [candidatas, setCandidatas] = useState<CandidataType[]>([])
    const [votos, setVotos] = useState(0)

    useEffect(() => {
        
        const apiCandidatas = api.get('listCandidatas')
        .then((response) => {
            if(response.data.status==="OK"){
                if(response.data.situacao==="1" && response.data.votos>0){
                    setCandidatas(response.data.dados)
                    setVotos(response.data.votos)
                }else{
                    history('/compra')
                }
            }else{
                localStorage.clear()
                alert(response.data.msg)
                history('/login')
            }
        })
    }, [])
    return {candidatas, votos}

}