import { useNavigate, useParams } from "react-router-dom";
import { Button } from "./Button";
import { FormEvent } from "react";
import { CandidataType } from "../hooks/useCandidatas";
import api from "../services/api";

type CandidataTypeProps = {
    candidata: CandidataType | undefined
}

export function FooterCandidataVote({candidata}:CandidataTypeProps){
    const history = useNavigate()
    const params = useParams()
    


    async function handleSubmit(event: FormEvent) {
        event.preventDefault()
        const response = await api.post('votar', new URLSearchParams({
            id_candidata: ''+params.id
        }))
        if(response.data.status==="OK"){
            alert(response.data.msg)
            history('/')
        }else{
            alert(response.data.msg)
        }
    }

    return(
        <div>
            <footer>
            <div className="user-info">
                    <span><b>{candidata?.nome}</b></span>
                    <span>{candidata?.cidade}</span>
                </div>
                <div>
                    <form onSubmit={ handleSubmit }>
                        <Button type="submit">Enviar</Button>
                    </form>
                </div>
            </footer>
        </div>
    )
}