import { ReactNode } from 'react'
import { CandidataType } from '../hooks/useCandidatas';
import '../styles/candidata.scss'



type QuestionProps = {
    content: CandidataType | undefined,
    children?: ReactNode;
}



export function Candidata({content, children}:QuestionProps){
    
    
    return (
        
        <div key={content?.id} className="question">
            <img src={content?.foto} alt={content?.nome} />
            {children}
        </div>
    )
}