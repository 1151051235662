
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import '../styles/home.scss'
import { Candidata } from '../componentes/Candidata'
import { useNavigate } from 'react-router-dom'
import { useCandidata } from '../hooks/useCandidata'
import { FooterCandidataVote } from '../componentes/FooterCandidataVote'

export function Vote(){
    const history = useNavigate()
    const { candidata  } = useCandidata()
    
    function logout(){
        localStorage.clear()
        history('/login')
    }

    function voltar(){
        history('/')
    }
    
    return(
        <div id="page-room">
            <header>
                <div className="content">
                    <img src={logoImg} alt="Logo"/>
                    <Button onClick={logout}>Sair</Button>
                </div>
            </header>

            <main className='content'>
                <div className='room-title'>
                    <Button onClick={voltar}>Home</Button>
                </div>
                <div className='question-list'>
                    <div className='list-flex'>
                        <Candidata key={candidata?.id}
                            content={candidata}>
                                <FooterCandidataVote candidata={candidata} />
                        </Candidata>
                    </div>
                </div>
                
            </main>
        </div>
    )
}