
import logoImg from '../assets/img/logo.png'
import { useNavigate } from "react-router-dom"
import '../styles/cadastro.scss'
import { Button } from '../componentes/Button'
import { FormEvent, useState } from 'react'
import api from '../services/api'
import InputMask from 'react-input-mask'



export function Cadastro(){
    const [nome, setNome] = useState('')
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [CPF, setCPF] = useState('')
    const [celular, setCelular] = useState('')

    const history = useNavigate()

    async function handleCompra(event:FormEvent){
        event.preventDefault()
        const response = await api.post('cadastraUsuario', new URLSearchParams({
            nome: nome,
            email: login,
            senha: senha,
            cpf: CPF,
            celular: celular
        }))
        if(response.data.status==="OK"){
            localStorage.setItem("token",response.data.token)
            history('/')
        }else{
            alert(response.data.msg)
        }
    }
    return (
        <div id="page-compra">
            <header>
                <div className="content">
                    <img src={logoImg} alt="Logo"/>
                </div>
            </header>
            <main className='content'>
                <div className='room-title'>
                    <h1>Cadastro</h1>
                    <h3>Faça seu cadastro para ser jurado do concurso</h3>
                </div>
                <div className='compra-list'>
                    <form onSubmit={handleCompra}>
                    <input 
                            type="text"
                            placeholder='Nome'
                            onChange={event => setNome(event.target.value)}
                            value={nome}
                        />
                        <input 
                            type="text"
                            placeholder='Login'
                            onChange={event => setLogin(event.target.value)}
                            value={login}
                        />
                        <input 
                            type="password"
                            placeholder='Senha'
                            onChange={event => setSenha(event.target.value)}
                            value={senha}
                        />
                        <InputMask
                            name="cpf"
                            mask="999.999.999-99"
                            value={CPF}
                            onChange={event => setCPF(event.target.value)}
                            placeholder='CPF'
                        />
                        <InputMask
                            name="celular"
                            mask="(99)99999-9999"
                            value={celular}
                            onChange={event => setCelular(event.target.value)}
                            placeholder='Celular'
                            alwaysShowMask = {false}
                        />
                        <Button>Cadastrar</Button>
                    </form>
                
                </div>
            </main>
        </div>
    )

}