import './componentes/Button'
import './styles/global.scss'

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { RequireAuth } from './context/RequireAuth'
import { Vote } from './pages/Vote'
import { Page404 } from './pages/Page404'
import { Cadastro } from './pages/Cadastro'
import { Compra } from './pages/Compra'
import { Valida } from './pages/Valida'



function App() {
  
  return (
    <BrowserRouter>
        <Routes>
            <Route path="*" element={<Page404 />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cadastro" element={<Cadastro />} />
            <Route path="/" element={
              <RequireAuth>
                <Home />
              </RequireAuth>
                } 
            />

            <Route path="/vote/:id" element={
              <RequireAuth>
                <Vote />
              </RequireAuth>
                } 
            />

            <Route path="/compra" element={
              <RequireAuth>
                <Compra />
              </RequireAuth>
                } 
            />

            <Route path="/valida/:id" element={<Valida />} />
            
        </Routes> 
    </BrowserRouter>
  );
}

export default App;
