
import '../styles/compra.scss'
import logoImg from '../assets/img/logo.png'
import { Button } from '../componentes/Button'
import { useNavigate } from 'react-router-dom'
import { useCandidatas } from '../hooks/useCandidatas'
import { FormEvent,  useEffect,  useState } from 'react'
import { useUserAuth } from '../hooks/useUserAuth'
import api from '../services/api'
import InputMask from 'react-input-mask'



export function Compra(){
    const history = useNavigate()
    const {  votos } = useCandidatas()
    const {user} = useUserAuth()

    const [quant, setQuant] = useState('')
    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [valor, setValor] = useState('')
    const [cardnumber, setCardNumber] = useState('')
    const [expmonth, setExpmonth] = useState('')
    const [expyear, setExpyear] = useState('')
    const [cvv, setCvv] = useState('')
    
    function logout(){
        localStorage.clear()
        history('/login')
    }

    async function handleCompra(event:FormEvent){
        event.preventDefault()
        const response = await api.post('pagarme', new URLSearchParams({
            nome: nome,
            email: email,
            valor: valor,
            quant: quant,
            cardnumber: cardnumber,
            expmonth: expmonth,
            expyear: expyear,
            cvv: cvv
        }))
        if(response.data.status==="OK"){
            alert(response.data.msg)
            history('/')
        }else{
            alert(response.data.msg)
        }
    }

    async function atualizaValor(event:string){
        setQuant(event)
        let valorTemp = parseFloat(event)*10
        setValor (valorTemp.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}))
    }



    useEffect(() => {

        const carregaDados = async () =>{
            
            if(user?.nome){
                setNome(user?.nome)
            }
            if(user?.email){
                setEmail(user?.email)
            }
            atualizaValor("1")
        }
        carregaDados()

    }, [user?.nome, user?.email])
    
    return(
        <div id="page-room">
            <header>
                <div className="content">
                    <img src={logoImg} alt="Logo"/>
                    <Button onClick={logout}>Sair</Button>
                </div>
            </header>

            <main className='content'>
                <div className='room-voto'>
                    <span>Votos Disponíveis: {votos}</span>
                </div>
                <div className='room-title'>
                    <h1>Seus Votos acabaram</h1>
                </div> 
                <div className='compra-list'>
                    <form onSubmit={handleCompra}>
                    <input 
                            readOnly
                            type='text'
                            placeholder='Nome'
                            value={nome}
                        />
                        <input 
                            readOnly
                            type='text'
                            placeholder='E-mail'
                            value={email}
                        />
                        <InputMask
                            name="cardnumber"
                            mask="9999 9999 9999 9999"
                            value={cardnumber}
                            onChange={event => setCardNumber(event.target.value)}
                            placeholder='Cartão de Crédito'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            name="expmonth"
                            mask="99"
                            value={expmonth}
                            onChange={event => setExpmonth(event.target.value)}
                            placeholder='Mês Expiração'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            name="expyear"
                            mask="99"
                            value={expyear}
                            onChange={event => setExpyear(event.target.value)}
                            placeholder='Ano Expiração'
                            alwaysShowMask = {false}
                        />
                        <InputMask
                            name="cvv"
                            mask="999"
                            value={cvv}
                            onChange={event => setCvv(event.target.value)}
                            placeholder='CVV'
                            alwaysShowMask = {false}
                        />
                        
                        <select name="quant" value={quant} onChange={event => atualizaValor(event.target.value)} > 
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                        </select>

                        <input 
                            readOnly
                            type='text'
                            placeholder='Valor'
                            value={valor}
                        />
                        
                        <Button>Comprar</Button>
                    </form>
                
                </div>
            </main>
        </div>
    )
}